import React from "react";

interface TemperatureProps {
    temp: number,
}

export const Temperature: React.FC<TemperatureProps> = ({temp}) => {
    return (
        <span className="temp">
            <span className="temp-value">{Math.round(temp)}</span>
            <span className="deg">°</span>
        </span>
    );
};
