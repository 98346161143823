import React from "react";
import { WeatherData } from "../weather-data";
import { Temperature } from "../components/Temperature";

interface CurrentWeatherProps {
    weatherData: WeatherData | null,
}

export const CurrentWeather: React.FC<CurrentWeatherProps> = props => {
    const { weatherData } = props;
    if (weatherData == null) {
        return (
            <div className="current-weather-widget widget-disabled"></div>
        );
    }

    const currentConditions = weatherData.current.weather[0];
    const hourlyTemps = weatherData.hourly.map(weather => weather.temp);
    const hi = Math.max(...hourlyTemps);
    const lo = Math.min(...hourlyTemps);
    const humidity = weatherData.current.humidity;
    const pressureHPa = weatherData.current.pressure;
    const pressure = 0.02952998751 * pressureHPa; // hPa to inHg

    return (
        <div className="current-weather-widget">
            <div className="current-weather">
                <div className="current-conditions">
                    <img
                        className="current-conditions-icon"
                        alt=""
                        src={`https://openweathermap.org/img/wn/${currentConditions.icon}@2x.png`}
                    />
                    <span className="current-conditions-desc">{currentConditions.main}</span>
                </div>
                <div className="current-temp block-temp"><Temperature temp={weatherData.current.temp} /></div>
                <div className="current-extra current-high-low-temp">
                    Hi <Temperature temp={hi} /> / Lo <Temperature temp={lo} />
                </div>
                <div className="current-extra current-humidity-pressure">
                    {Math.round(humidity)}% / {Math.round(pressure * 10) / 10} inHg
                </div>
            </div>
        </div>
    );
};

