import React, { useEffect, useState } from "react";
import { WeatherData } from "./weather-data";
import { Clock } from "./widgets/Clock";
import { WeatherMap, Location } from "./widgets/WeatherMap";
import "./App.css";
import { CurrentWeather } from "./widgets/CurrentWeather";
import { WeatherForecast } from "./widgets/WeatherForecast";
import { HourlyForecast } from "./widgets/HourlyForecast";

const OPENWEATHERMAP_API_KEY: string = process.env.REACT_APP_OPENWEATHERMAP_API_KEY ?? "";
if (OPENWEATHERMAP_API_KEY === "") {
    console.warn("OpenWeatherMap API key was not set");
}

const DEFAULT_LOCATION = {
    lat: 45.5051,
    lon: -122.6750,
};

export const App: React.FC = () => {
    const currentDate = useCurrentDate();
    const timeInterval15Min = timeInterval(currentDate, 15 * 60);
    const timeInterval1Day = timeInterval(currentDate, 24 * 60 * 60);
    const currentLocation = useCurrentLocation(timeInterval1Day);
    const weatherData = useCurrentWeatherData({
        location: currentLocation,
        openWeatherMapApiKey: OPENWEATHERMAP_API_KEY,
        timeInterval: timeInterval15Min,
    });

    return (
        <div className="weather-dash">
            <Clock currentDate={currentDate} />
            <WeatherMap
                currentLocation={currentLocation ?? DEFAULT_LOCATION}
                openWeatherMapApiKey={OPENWEATHERMAP_API_KEY}
                timeInterval={timeInterval15Min}
            />
            <CurrentWeather weatherData={weatherData} />
            <HourlyForecast weatherData={weatherData} currentDate={currentDate} />
            <WeatherForecast weatherData={weatherData} currentDate={currentDate} />
        </div>
    );
};

function useCurrentDate(): Date {
    const [currentDate, setCurrentDate] = useState(new Date());
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentDate(new Date());
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    });

    return currentDate;
}

function useCurrentLocation(timeInterval: number): Location | null {
    const [location, setLocation] = useState<Location | null>(null);

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(pos => {
            setLocation({
                lat: pos.coords.latitude,
                lon: pos.coords.longitude,
            });
        });
    }, [timeInterval]);

    return location;
}

interface WeatherDataOptions {
    openWeatherMapApiKey: string,
    location: Location | null,
    timeInterval: number,
}

function useCurrentWeatherData(opts: WeatherDataOptions): WeatherData | null {
    const { location, openWeatherMapApiKey, timeInterval } = opts;

    const [weatherData, setWeatherData] = useState<WeatherData | null>(null);
    useEffect(() => {
        const fetchWeatherData = async () => {
            if (location == null) { return; }

            const { lat, lon } = location;
            const apiRequestUrl = `https://api.openweathermap.org/data/2.5/onecall?lat=${encodeURIComponent(lat)}&lon=${encodeURIComponent(lon)}&units=imperial&appid=${encodeURIComponent(openWeatherMapApiKey)}`;
            const response = await fetch(apiRequestUrl);
            const responseJson = await response.json();

            setWeatherData(responseJson);
        };

        fetchWeatherData();
    }, [location, openWeatherMapApiKey, timeInterval]);

    return weatherData;
}

/// Calculate the number of `intervalSec` seconds that has elapsed since
/// the Unix epoch, as of `date`. This is good for calculating a value that
/// only changes each `intervalSec` seconds.
function timeInterval(date: Date, intervalSec: number): number {
    const unixSeconds = date.getTime() / 1000;
    return Math.floor(unixSeconds / intervalSec);
}
