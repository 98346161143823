import React from "react";
import { WeatherData } from "../weather-data";
import { Temperature } from "../components/Temperature";

interface HourlyForecastProps {
    weatherData: WeatherData | null,
    currentDate: Date,
}

export const HourlyForecast: React.FC<HourlyForecastProps> = props => {
    const { weatherData, currentDate } = props;

    const dateFormat = Intl.DateTimeFormat("default", {month: "short", weekday: "short", day: "numeric"});
    const date = dateFormat.format(currentDate);

    if (weatherData == null) {
        return (
            <div className="hourly-forecast-widget widget-disabled">
                <div className="hourly-forecast">
                    <time
                        className="current-date"
                        dateTime={currentDate.toISOString()}
                    >
                        {date}
                    </time>
                </div>
            </div>
        );
    }

    return (
        <div className="hourly-forecast-widget">
            <div className="hourly-forecast">
                <time
                    className="current-date"
                    dateTime={currentDate.toISOString()}
                >
                    {date}
                </time>
                <ul className="hourly-list">
                    {weatherData.hourly.map(hourly => {
                        const hourTimestamp = new Date(hourly.dt * 1000);
                        const hourFormat = Intl.DateTimeFormat("default", {hour: "numeric"});
                        const hour = hourFormat.format(hourTimestamp);
                        const hourlyConditions = hourly.weather[0];
                        return (
                            <li key={hourly.dt}>
                                <time
                                    dateTime={hourTimestamp.toISOString()}
                                >
                                    {hour}
                                </time>
                                <span className="hourly-conditions">
                                    <img
                                        className="hourly-conditions-icon"
                                        alt=""
                                        src={`https://openweathermap.org/img/wn/${hourlyConditions.icon}@2x.png`}
                                    />
                                    <span className="hourly-temp">
                                        <Temperature temp={hourly.temp} />
                                    </span>
                                </span>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

