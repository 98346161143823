import React from "react";

interface ClockProps {
    currentDate: Date;
}

export const Clock: React.FC<ClockProps> = ({currentDate}) => {
    const currentSecond = currentDate.getSeconds();

    const timeFormat = Intl.DateTimeFormat("default", {hour: "numeric", minute: "numeric"});
    const timeParts = timeFormat.formatToParts(currentDate);
    const time = timeParts
        .filter(part => part.type !== "dayPeriod")
        .map(part => part.value)
        .join("")
        .trim();

    return (
        <div className="clock-widget">
            <div className="clock">
                <time dateTime={currentDate.toISOString()}>{time}</time>
                <div className="second-ticks">
                    {new Array(60).fill(undefined).map((_, secondTick) => {
                        const isIlluminated = currentSecond >= secondTick;
                        const classes = isIlluminated
                            ? "second-tick second-tick-illuminated"
                            : "second-tick";
                        return <span key={secondTick} className={classes}></span>
                    })}
                </div>
            </div>
        </div>
    );
};
