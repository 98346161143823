import React from "react";
import { Temperature } from "../components/Temperature";
import { WeatherData } from "../weather-data";

interface WeatherForecastProps {
    weatherData: WeatherData | null,
    currentDate: Date,
}

export const WeatherForecast: React.FC<WeatherForecastProps> = props => {
    const { weatherData } = props;
    if (weatherData == null) {
        return (
            <div className="weather-forecast-widget widget-disabled"></div>
        );
    }

    const dailyForecast = new Array(5).fill(undefined).map((_, index) => {
        return weatherData.daily[index];
    });

    return (
        <div className="weather-forecast-widget">
            {dailyForecast.map(daily => {
                if (daily == null) {
                    return (
                        <div key="none" className="no-weather-forecast"></div>
                    );
                }

                const dateTimestamp = new Date(daily.dt * 1000);
                const dateFormat = Intl.DateTimeFormat("default", {
                    month: "short", day: "numeric"
                });
                const date = dateFormat.format(dateTimestamp);

                const hi = daily.temp.max;
                const lo = daily.temp.min;
                const forecastConditions = daily.weather[0];
                const humidity = daily.humidity;
                const pressureHPa = daily.pressure;
                const pressure = 0.02952998751 * pressureHPa; // hPa to inHg
                const precipitation = daily.pop;

                return (
                    <div key={daily.dt} className="daily-forecast">
                        <time
                            className="forecast-date"
                            dateTime={dateTimestamp.toISOString()}
                        >
                            {date}
                        </time>
                        <div className="forecast-weather">
                            <div className="forecast-conditions">
                                <img
                                    className="forecast-conditions-icon"
                                    alt=""
                                    src={`https://openweathermap.org/img/wn/${forecastConditions.icon}@2x.png`}
                                />
                                <span className="forecast-conditions-desc">{forecastConditions.main}</span>
                            </div>
                            <div className="forecast-extra">
                                <div className="forecast-hi-lo-temp">
                                    <Temperature temp={hi} /> / <Temperature temp={lo} />
                                </div>
                                <div className="forecast-humidity-pressure">
                                    {Math.round(humidity)}% / {Math.round(pressure * 10) / 10}
                                </div>
                                {precipitation > 0.05
                                    ? (
                                    <div className="forecast-precip">
                                        Precip: {Math.round(precipitation * 100)}%
                                    </div>
                                    ) : null}
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
