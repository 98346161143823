import React, { useEffect, useState } from "react";
import { Map, TileLayer, Viewport } from "react-leaflet";
import "leaflet/dist/leaflet.css";

export interface Location {
    lat: number,
    lon: number,
}

interface WeatherMapProps {
    currentLocation: Location,
    openWeatherMapApiKey: string,
    timeInterval: number,
}

const DEFAULT_ZOOM = 8;

export const WeatherMap: React.FC<WeatherMapProps> = props => {
    const {lat, lon} = props.currentLocation;
    const timeInterval = props.timeInterval;

    const [viewport, setViewport] = useState<Viewport>({
        center: [lat, lon],
        zoom: DEFAULT_ZOOM,
    });

    useEffect(() => {
        setViewport({
            center: [lat, lon],
            zoom: DEFAULT_ZOOM,
        });
    }, [lat, lon]);


    // NOTE: In some cases, we use string interpolation in the tile URLs. This
    // causes Leaflet/React-Leaflet to re-fetch the layer tiles whenever
    // the interpolated value changes (which is what we want).
    return (
        <div className="weather-map-widget">
            <Map viewport={viewport}>
                <TileLayer
                    url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
                    attribution="© OpenStreetMap contributors"
                />
                <TileLayer
                    url={`https://{s}.tile.openweathermap.org/map/{layer}/{z}/{x}/{y}.png?appid={appid}&t=${timeInterval}`}
                    attribution="© OpenWeather CC-BY-SA 4.0"
                    layer="precipitation_new"
                    appid={props.openWeatherMapApiKey}
                />
                <TileLayer
                    url={`https://tiles.lightningmaps.org/?x={x}&y={y}&z={z}&s=256&t=4&T=${timeInterval}`}
                    attribution="© Lightningmaps.org contributors CC-BY-SA 4.0"
                />
            </Map>
        </div>
    );
};
